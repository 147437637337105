exports.components = {
  "component---src-containers-blog-post-index-tsx": () => import("./../../../src/containers/BlogPost/index.tsx" /* webpackChunkName: "component---src-containers-blog-post-index-tsx" */),
  "component---src-containers-posts-index-tsx": () => import("./../../../src/containers/Posts/index.tsx" /* webpackChunkName: "component---src-containers-posts-index-tsx" */),
  "component---src-containers-products-categories-index-tsx": () => import("./../../../src/containers/ProductsCategories/index.tsx" /* webpackChunkName: "component---src-containers-products-categories-index-tsx" */),
  "component---src-containers-products-index-tsx": () => import("./../../../src/containers/Products/index.tsx" /* webpackChunkName: "component---src-containers-products-index-tsx" */),
  "component---src-containers-single-product-container-index-tsx": () => import("./../../../src/containers/SingleProductContainer/index.tsx" /* webpackChunkName: "component---src-containers-single-product-container-index-tsx" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-tsx": () => import("./../../../src/pages/About.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-checkout-tsx": () => import("./../../../src/pages/Checkout.tsx" /* webpackChunkName: "component---src-pages-checkout-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-login-tsx": () => import("./../../../src/pages/Login.tsx" /* webpackChunkName: "component---src-pages-login-tsx" */),
  "component---src-pages-profile-tsx": () => import("./../../../src/pages/Profile.tsx" /* webpackChunkName: "component---src-pages-profile-tsx" */),
  "component---src-pages-register-tsx": () => import("./../../../src/pages/Register.tsx" /* webpackChunkName: "component---src-pages-register-tsx" */)
}

